import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import styled, { css, useTheme } from "styled-components"
import { graphql } from "gatsby"

import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Image from "@components/image"

import Gradient from "@images/pm-standard-gradient.png"
import { processImages } from "@utils/process-images"

import media from "@styles/media"

import Span from "@components/elements/span"
import { ReactComponent as Information } from "@images/comparison/information.svg"
import Button from "@components/elements/button"
import { Tooltip } from "@components/common/tooltip"
import Anchor from "@components/elements/anchor"

import KlipsLogo from "@images/logo/klips.svg"
import PowerMetricsLogo from "@images/logo/powermetrics.svg"
import GradientText from "@components/common/gradient-text"
import { useState } from "react"
import { useCallback } from "react"

const GlassyDiv = styled(Div)`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  box-shadow: ${props => props.theme.shadow.glassy};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.59);
  ${media.sm`
    ${props =>
      props.reverse &&
      css`
        order: -1;
      `}
  `}
`

const ImageGradient = styled.img`
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  min-width: 2500px;
  z-index: 0;
  height: auto;
`

const MigratingGA4 = ({ data }) => {
  const { shadow } = useTheme()
  const images = processImages(data.images.edges)
  const [toggled, setToggled] = useState(false)

  return (
    <Layout
      title={`Migrating to Google Analytics 4`}
      description={`Google Analytics 4 is replacing Universal Analytics on July 1, 2023. We'll help you migrate your existing Klipfolio dashboards to GA4.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <Flex gap="6rem" margin="0 0 6rem">
          <Flex background="#0F1124" position="relative" overflow="hidden">
            <ImageGradient src={Gradient} />
            <Container>
              <Div margin="8rem auto 6rem">
                <Image
                  eager
                  height={200}
                  objectFit="scale-down"
                  file={images["718d0bc6-81dd-44f3-ba2d-76a4a97e0ead"]}
                />
                <Heading
                  center
                  fontSize="2.4rem"
                  color="white"
                  margin="2rem 0 2rem"
                >
                  Working with Google Analytics 4 in Klipfolio
                </Heading>
                <Paragraph
                  lineHeight="1.6"
                  color="white"
                  center
                  margin="0 auto 2rem"
                  maxWidth="800px"
                >
                  Google Analytics 4 is replacing Universal Analytics on July 1,
                  2023. On this page you&apos;ll find the resources you need to
                  connect to your GA4 data and migrate your existing Klipfolio
                  dashboards to Google Analytics 4.
                </Paragraph>
              </Div>
            </Container>
          </Flex>
          <Div>
            <img
              src={PowerMetricsLogo}
              style={{
                height: "80px",
                margin: "0 auto 2rem",
                display: "block",
              }}
            />
            <Selector toggled={toggled} setToggled={setToggled} />
            {!toggled && (
              <Container>
                <Grid
                  gap="2rem"
                  columns="2fr 2fr"
                  columnsMd="1fr 1fr"
                  columnsSm="1fr"
                  margin="0 0 2rem"
                >
                  <FeaturedGA4
                    name="Connecting to Google Analytics 4"
                    summary={`Whether you’re creating instant or custom metrics, one of your first steps is connecting PowerMetrics to your Google Analytics 4 data.`}
                    image={images["8bee0f2a-b6f7-4fb6-939e-aca76810968a"]}
                    slug={`https://support.klipfolio.com/hc/en-us/articles/10413252064279`}
                    type="Knowledge Base"
                    bottom
                  />
                  <FeaturedGA4
                    name="Adding Google Analytics 4 Metrics"
                    summary={`Visualize and track your Google Analytics 4 data with instant and custom metrics.`}
                    image={images["00b171c5-a5a8-44f4-a2dc-7c3735bb4b5d"]}
                    slug={`https://support.klipfolio.com/hc/en-us/articles/10562724029975`}
                    type="Knowledge Base"
                    bottom
                  />
                </Grid>
                <Grid
                  gap="2rem"
                  columns="repeat(3, 1fr)"
                  columnsMd="1fr 1fr"
                  columnsSm="1fr"
                >
                  <FeaturedGA4Small
                    name="Tracking GA4 Events"
                    summary={`This example walks you through retrieving Google Analytics 4 event data and creating custom metrics.`}
                    image={images["58ef4a7e-db02-4c47-9db3-9e6da169699a"]}
                    slug={`https://support.klipfolio.com/hc/en-us/articles/12213117240087`}
                    type="Examples"
                  />
                  <FeaturedGA4Small
                    name="Tracking GA4 Custom Events"
                    summary={`This example walks you through retrieving Google Analytics 4 custom event data and creating custom metrics.`}
                    image={images["ba1c9715-4427-4202-b516-5f6c3ee541c5"]}
                    slug={`https://support.klipfolio.com/hc/en-us/articles/12070911665175`}
                    type="Examples"
                  />
                  <FeaturedGA4Small
                    name="Community Posts"
                    summary={`Need help writing Google Analytics 4 queries or connecting to your Google Analytics 4 data? Go here for some tips.`}
                    image={images["d4807499-3e81-4ddb-83f4-dfc47c23be66"]}
                    slug={`https://support.klipfolio.com/hc/en-us/community/posts/12981463873559`}
                    type="Tips from Klipfolio Experts"
                  />
                  <FeaturedGA4Small
                    name="How to use the Google Analytics 4 Query Explorer"
                    summary={`The Google Analytics 4 Query Explorer is a fantastic way to retrieve raw web analytics data for your website.`}
                    image={images["81bd83de-df11-4ec6-b17e-47d3da67b35b"]}
                    slug={`/blog/google-analytics-query-explorer`}
                    type="Blog"
                  />
                </Grid>
              </Container>
            )}
            {toggled && (
              <Container>
                <Grid
                  gap="2rem"
                  columns="2fr 2fr"
                  columnsMd="1fr 1fr"
                  columnsSm="1fr"
                  margin="0 0 2rem"
                >
                  <FeaturedGA4
                    name="Connecting to Google Analytics 4"
                    summary={`Whether you’re creating instant or custom metrics, one of your first steps is connecting PowerMetrics to your Google Analytics 4 data.`}
                    image={images["8bee0f2a-b6f7-4fb6-939e-aca76810968a"]}
                    slug={`https://support.klipfolio.com/hc/en-us/articles/4410648083863`}
                    type="Knowledge Base"
                    bottom
                    alt
                  />
                  <FeaturedGA4
                    name="Adding Google Analytics 4 Metrics"
                    summary={`Visualize and track your Google Analytics 4 data with instant and custom metrics.`}
                    image={images["00b171c5-a5a8-44f4-a2dc-7c3735bb4b5d"]}
                    slug={`https://support.klipfolio.com/hc/en-us/articles/4410724510231`}
                    type="Knowledge Base"
                    bottom
                    alt
                  />
                </Grid>
                <Grid
                  gap="2rem"
                  columns="repeat(3, 1fr)"
                  columnsMd="1fr 1fr"
                  columnsSm="1fr"
                >
                  <FeaturedGA4Small
                    name="Tracking GA4 Events"
                    summary={`This example walks you through retrieving Google Analytics 4 event data and creating custom metrics.`}
                    image={images["58ef4a7e-db02-4c47-9db3-9e6da169699a"]}
                    slug={`https://support.klipfolio.com/hc/en-us/articles/12207662448151`}
                    type="Examples"
                    alt
                  />
                  <FeaturedGA4Small
                    name="Tracking GA4 Custom Events"
                    summary={`This example walks you through retrieving Google Analytics 4 custom event data and creating custom metrics.`}
                    image={images["ba1c9715-4427-4202-b516-5f6c3ee541c5"]}
                    slug={`https://support.klipfolio.com/hc/en-us/articles/11964364351767`}
                    type="Examples"
                    alt
                  />
                  <FeaturedGA4Small
                    name="Community Posts"
                    summary={`Need help writing Google Analytics 4 queries or connecting to your Google Analytics 4 data? Go here for some tips.`}
                    image={images["d4807499-3e81-4ddb-83f4-dfc47c23be66"]}
                    slug={`https://support.klipfolio.com/hc/en-us/community/posts/12981463873559`}
                    type="Tips from Klipfolio Experts"
                    alt
                  />
                  <FeaturedGA4Small
                    name="How to use the Google Analytics 4 Query Explorer"
                    summary={`The Google Analytics 4 Query Explorer is a fantastic way to retrieve raw web analytics data for your website.`}
                    image={images["81bd83de-df11-4ec6-b17e-47d3da67b35b"]}
                    slug={`/blog/google-analytics-query-explorer`}
                    type="Blog"
                    alt
                  />
                </Grid>
              </Container>
            )}
          </Div>

          <Div background="#0F1124" overflow="hidden">
            <Container>
              <Div position="relative">
                <ImageGradient src={Gradient} />
                <Grid
                  columns="1fr 1fr"
                  columnsMd="1fr"
                  gap="4rem"
                  gapSm="2rem"
                  padding="6rem 0"
                  paddingSm="2rem 0"
                  alignItems="center"
                >
                  <Div zIndex={10}>
                    <Paragraph
                      fontSize="1rem"
                      fontWeight="700"
                      margin="0 0 1rem"
                      color="white"
                    >
                      Blog Post
                    </Paragraph>
                    <Heading as="h2" color="white" margin="0 0 1rem">
                      Migrating your digital marketing dashboards to Google
                      Analytics 4
                    </Heading>
                    <Paragraph
                      lineHeight="1.6"
                      color="white"
                      maxWidth="45ch"
                      margin="0 0 2rem"
                    >
                      {`Marketing expert Jon Taylor shows you how to migrate your reporting from Universal Analytics to Google Analytics 4.`}
                    </Paragraph>
                    <Button
                      href={"/blog/guide-migrating-to-ga4"}
                      arrow
                      background="#4a56c4"
                      color="white"
                      notice={false}
                    >
                      Learn More
                    </Button>
                  </Div>

                  <GlassyDiv
                    height="500px"
                    overflow="hidden"
                    padding="2rem 2rem 0 2rem"
                  >
                    <Flex>
                      <Heading
                        as="h2"
                        fontSize="2rem"
                        margin="0 0 1rem"
                        color="white"
                      >
                        GA4 Instant Metrics
                      </Heading>
                      <Paragraph
                        lineHeight="1.6"
                        fontSize="1.1rem"
                        color="white"
                        margin="0 0 1rem"
                      >
                        Get started quickly by using our Google Analytics 4
                        Instant Metrics. Import your own data into your account
                        in less than 1 minute.
                      </Paragraph>
                      <Anchor
                        color="white"
                        link={
                          "https://www.klipfolio.com/metrics/services/google-analytics-4/"
                        }
                        arrow
                        margin="0 0 2rem"
                      >
                        Get Started
                      </Anchor>
                      <Image
                        radius={0.5}
                        borderStyle={{ boxShadow: shadow.default }}
                        file={images["4a15e3cf-e351-4a00-b0d1-c1a6599ccf85"]}
                      />
                    </Flex>
                  </GlassyDiv>
                </Grid>
              </Div>
            </Container>
          </Div>
          <Container id="klips" style={{ scrollMarginTop: "8rem" }}>
            <img
              src={KlipsLogo}
              style={{
                height: "80px",
                margin: "0 auto 4rem",
                display: "block",
              }}
            />
            <Grid
              gap="2rem"
              columns="2fr 2fr"
              columnsMd="1fr 1fr"
              columnsSm="1fr"
              margin="0 0 2rem"
            >
              <FeaturedGA4
                name="GA4 Connector"
                summary={`Connect to your Google Analytics 4 data. Get started with data sources and custom Klips.`}
                image={images["9e19daef-9d5e-4d3e-b743-375a66b28443"]}
                slug={`https://support.klipfolio.com/hc/en-us/articles/13268099902231`}
                type="Knowledge Base"
                bottom
              />
              <FeaturedGA4
                name="Klip Gallery: Google Analytics 4"
                summary={`Take the fast track to visualizing your Google Analytics 4 data with pre-built Klips.`}
                image={images["69408d2c-d687-4cfd-bd56-4eeca5bbac63"]}
                slug={`https://www.klipfolio.com/integrations/google-analytics-4`}
                type="Examples"
                bottom
              />
              <FeaturedGA4
                name="Building Google Analytics 4 Dashboards in Klips"
                summary={`This blog post shows you how to build GA4 web analytics dashboards using Klips.`}
                image={images["6ca810e2-cb7f-4e16-93cb-ab0d39e70820"]}
                slug={`/blog/build-GA4-dashboards-klips`}
                type="Blog"
                bottom
              />
            </Grid>
          </Container>
        </Flex>
      </Container>
    </Layout>
  )
}

const FeaturedGA4 = ({ name, summary, slug, image, type, bottom, alt }) => {
  const { shadow, color } = useTheme()

  return (
    <Flex
      background={!alt ? "#f3f5fc" : "#EBEFFF"}
      justifyContent="center"
      borderRadius="1rem"
      padding="3rem"
    >
      {!bottom && (
        <Div>
          <Image
            radius={0.25}
            shadow
            file={image}
            showOverflow
            borderStyle={{
              boxShadow: shadow.dramatic,
            }}
            style={{
              marginBottom: "3rem",
            }}
          />
        </Div>
      )}
      <Div center zIndex={1}>
        <Paragraph fontSize="1rem" fontWeight="700" center margin="0 0 0.5rem">
          <GradientText variant="powermetrics">
            {type}
          </GradientText>
        </Paragraph>
        <Heading as="h2" fontSize="1.6rem" center margin="0 0 1.5rem">
          {name}
        </Heading>
        <Paragraph
          lineHeight="1.6"
          center
          fontSize="1.1rem"
          margin="0 0 1.5rem"
        >
          {summary}
        </Paragraph>
        <Anchor
          arrow
          color={color.indigo500}
          hover={color.indigo300}
          link={slug}
        >
          Learn More
        </Anchor>
      </Div>
      {bottom && (
        <Div style={{ marginTop: "auto" }}>
          <Image
            radius={1}
            shadow
            noDimensions
            file={image}
            showOverflow
            borderStyle={{
              boxShadow: shadow.dramatic,
            }}
            style={{
              marginTop: "2rem",
            }}
          />
        </Div>
      )}
    </Flex>
  )
}

FeaturedGA4.propTypes = {
  name: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  bottom: PropTypes.bool,
  alt: PropTypes.bool,
}

const FeaturedGA4Small = ({ name, summary, slug, image, type }) => {
  const { shadow, color } = useTheme()

  return (
    <Div>
      <Flex
        background="#f3f5fc"
        borderRadius="1rem"
        padding="2rem"
        height="300px"
        margin="0 0 2rem"
        justifyContent="center"
      >
        <Image
          radius={0.5}
          shadow
          file={image}
          showOverflow
          borderStyle={{
            boxShadow: shadow.dramatic,
          }}
        />
      </Flex>
      <Paragraph fontSize="1rem" fontWeight="700" margin="0 0 0.5rem">
        <GradientText variant="powermetrics">
          {type}
        </GradientText>
      </Paragraph>
      <Heading as="h3" fontSize="1.4rem" margin="0 0 1.5rem">
        {name}
      </Heading>
      <Paragraph lineHeight="1.6" fontSize="1rem" margin="0 0 1.5rem">
        {summary}
      </Paragraph>
      <Anchor color={color.indigo500} hover={color.indigo300} arrow link={slug}>
        Learn More
      </Anchor>
    </Div>
  )
}

FeaturedGA4Small.propTypes = {
  name: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

const Selector = ({ toggled, setToggled }) => {
  const { shadow, color } = useTheme()
  const [isHovering, setIsHovering] = useState(false)
  const handleMouseOver = useCallback(() => setIsHovering(true), [])
  const handleMouseOut = useCallback(() => setIsHovering(false), [])

  const handleToggle = useCallback(() => {
    setToggled(!toggled)
  }, [toggled, setToggled])

  return (
    <Flex
      margin="0 auto 4rem"
      flexFlow="row"
      alignItems="center"
      justifyContent="center"
      gap="1rem"
    >
      <Flex
        boxShadow={shadow.light}
        padding="0.5rem 1.5rem"
        borderRadius={"2rem"}
        background="#f3f5fc"
        width="max-content"
        flexFlow="row"
        onClick={handleToggle}
        gap="1rem"
        cursor="pointer"
      >
        <Span
          color={!toggled ? color.indigo400 : color.indigo600}
          fontWeight={!toggled ? "700" : "500"}
        >
          With Data Feeds
        </Span>
        <Span
          color={toggled ? color.indigo400 : color.indigo600}
          fontWeight={toggled ? "700" : "500"}
        >
          Without Data Feeds
        </Span>
      </Flex>

      <Flex
        cursor="pointer"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        hideOnMd
        alignItems="center"
      >
        <Information />
        <Tooltip isHovering={isHovering}>
          Our recent redesign simplifies the data transfer experience by
          combining raw and modelled data sources into a single object - Data
          Feeds. We’re gradually releasing this new feature to our
          customers.&nbsp;
          <Anchor
            link={
              "https://support.klipfolio.com/hc/en-us/articles/8211202327447-Introduction-to-data-feeds?query=data%20feeds"
            }
          >
            Learn more.
          </Anchor>
        </Tooltip>
      </Flex>
    </Flex>
  )
}

Selector.propTypes = {
  toggled: PropTypes.bool.isRequired,
  setToggled: PropTypes.func.isRequired,
}

MigratingGA4.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query MigratingGA4Query {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "718d0bc6-81dd-44f3-ba2d-76a4a97e0ead"
            "8a8ad455-795c-4bfa-baaa-0eeab9d31dbe"
            "58ef4a7e-db02-4c47-9db3-9e6da169699a"
            "8bee0f2a-b6f7-4fb6-939e-aca76810968a"
            "00b171c5-a5a8-44f4-a2dc-7c3735bb4b5d"
            "58ef4a7e-db02-4c47-9db3-9e6da169699a"
            "ba1c9715-4427-4202-b516-5f6c3ee541c5"
            "d4807499-3e81-4ddb-83f4-dfc47c23be66"
            "9e19daef-9d5e-4d3e-b743-375a66b28443"
            "69408d2c-d687-4cfd-bd56-4eeca5bbac63"
            "4a15e3cf-e351-4a00-b0d1-c1a6599ccf85"
            "6ca810e2-cb7f-4e16-93cb-ab0d39e70820"
            "81bd83de-df11-4ec6-b17e-47d3da67b35b"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`

export default MigratingGA4
